import React from "react";
import { Col, Container, Row } from "reactstrap";
import { useTranslation } from "react-i18next";

import BackgroundImage1 from "../assets/images/bg/hero-bg.jpg";

export default function SectionHome() {
  const { t } = useTranslation();
  return (
    <>
      <section
        className="bg-home d-flex align-items-center"
        style={{ background: `url(${BackgroundImage1})` }}
        id="home"
      >
        <div className="bg-overlay bg-linear-gradient-3"></div>
        <Container>
          <Row>
            <Col>
              <div className="title-heading">
                <h1 className="heading text-white title-dark mb-4">
                  {t("section_home.title.t1")} <br />{" "}
                  {t("section_home.title.t2")}
                </h1>
                <p className="para-desc text-white-50">
                  {t("section_home.desc")}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}
