/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from "react";
import TinySlider from "tiny-slider-react";
import { Container, Row } from "reactstrap";
import { useTranslation } from "react-i18next";

import "tiny-slider/dist/tiny-slider.css";

const settings = {
  controls: false,
  mouseDrag: true,
  loop: true,
  rewind: true,
  autoplay: true,
  autoplayButtonOutput: false,
  autoplayTimeout: 3000,
  navPosition: "bottom",
  speed: 400,
  gutter: 12,
  responsive: {
    992: {
      items: 3,
    },

    767: {
      items: 2,
    },

    320: {
      items: 1,
    },
  },
};

export default function SectionTestimonial() {
  const { t, i18n } = useTranslation();
  const reviews = () => [
    {
      id: "1",
      name: "Sukardi",
      designation: t("section_testimonial.reviews.r1.designation"),
      description: t("section_testimonial.reviews.r1.description"),
    },
    {
      id: "2",
      name: "Nurul Hidayah",
      designation: t("section_testimonial.reviews.r2.designation"),
      description: t("section_testimonial.reviews.r2.description"),
    },
    {
      id: "3",
      name: "Wayan Bagus",
      designation: t("section_testimonial.reviews.r3.designation"),
      description: t("section_testimonial.reviews.r3.description"),
    },
    {
      id: "4",
      name: "Siti Maesaroh",
      designation: t("section_testimonial.reviews.r4.designation"),
      description: t("section_testimonial.reviews.r4.description"),
    },
    {
      id: "5",
      name: "Joko Sulistyo",
      designation: t("section_testimonial.reviews.r5.designation"),
      description: t("section_testimonial.reviews.r5.description"),
    },
  ];

  const reviewsData = useMemo(() => reviews(), [i18n.language]);

  return (
    <>
      <section className="section bg-light" id="testimonial">
        <Container>
          <Row className="justify-content-center">
            <div className="col-12">
              <div className="section-title text-center mb-4 pb-2">
                <h4 className="title mb-3">{t("section_testimonial.title")}</h4>
                <p className="text-muted para-desc mx-auto mb-0">
                  {t("section_testimonial.desc")}
                </p>
              </div>
            </div>
          </Row>

          <Row>
            <div className="col-12 mt-4">
              <div className="tiny-three-item">
                <TinySlider settings={settings} className="tiny-slide">
                  {reviewsData.map((el, index) => (
                    <div className="customer-testi m-1" key={index}>
                      <div className="content p-3 shadow rounded bg-white position-relative">
                        <ul className="list-unstyled mb-0 text-warning">
                          <li className="list-inline-item">
                            <i className="mdi mdi-star"></i>
                          </li>
                          <li className="list-inline-item">
                            <i className="mdi mdi-star"></i>
                          </li>
                          <li className="list-inline-item">
                            <i className="mdi mdi-star"></i>
                          </li>
                          <li className="list-inline-item">
                            <i className="mdi mdi-star"></i>
                          </li>
                          <li className="list-inline-item">
                            <i className="mdi mdi-star"></i>
                          </li>
                        </ul>
                        <p className="text-muted mt-2">{el.description}</p>
                      </div>
                      <div className="text-center mt-3">
                        <p className="text-primary mt-3 mb-0">
                          {el.name}{" "}
                          <small className="text-muted d-block ms-2">
                            {el.designation}
                          </small>
                        </p>
                      </div>
                    </div>
                  ))}
                </TinySlider>
              </div>
            </div>
          </Row>
        </Container>
      </section>
    </>
  );
}
