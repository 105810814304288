import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import { id, en } from "./translations";

const resources = {
  id: { translation: id },
  en: { translation: en },
};

i18next.use(initReactI18next).init({
  resources,
  compatibilityJSON: "v3",
  lng: "en",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});

export default i18next;
