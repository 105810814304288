import React, { useState } from "react";
import { Col, Container, Row } from "reactstrap";
import { useTranslation } from "react-i18next";

export default function SectionContactUs() {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    companyName: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const whatsappMessage = `*------------* this message was sent via nadi.biz.id_ *------------*
*_Name_*: ${formData.name}
*_Email_*: ${formData.email}
*_Phone_*: ${formData.phone}
*_Message_*: ${formData.message}`;

    const encodedMessage = encodeURIComponent(whatsappMessage);

    const phoneNumber = "6281230577970";

    // Buka WhatsApp
    window.open(
      `https://wa.me/${phoneNumber}?text=${encodedMessage}`,
      "_blank"
    );
  };

  return (
    <section className="section" id="contact">
      <Container>
        <Row className="justify-content-center">
          <Col>
            <div className="section-title text-center mb-4 pb-2">
              <h4 className="title mb-3">{t("section_contact_us.title")}</h4>
              <p className="text-muted para-desc mb-0 mx-auto">
                {t("section_contact_us.desc")}
              </p>
            </div>
          </Col>
        </Row>

        <Row className="align-items-center">
          <Col lg={8} md={6} className="order-md-2 order-1 mt-4 pt-2">
            <div className="p-4 rounded shadow bg-white">
              <form onSubmit={handleSubmit}>
                <Row>
                  <Col md={6}>
                    <div className="mb-4">
                      <input
                        name="name"
                        type="text"
                        className="form-control"
                        placeholder="Name :"
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </Col>

                  <Col md={6}>
                    <div className="mb-4">
                      <input
                        name="email"
                        type="email"
                        className="form-control"
                        placeholder="Email :"
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </Col>

                  <Col md={6}>
                    <div className="mb-4">
                      <input
                        name="phone"
                        type="text"
                        className="form-control"
                        placeholder="Phone :"
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </Col>

                  <Col md={6}>
                    <div className="mb-4">
                      <input
                        name="companyName"
                        type="text"
                        className="form-control"
                        placeholder="Company Name :"
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </Col>

                  <Col md={12}>
                    <div className="mb-4">
                      <textarea
                        name="message"
                        rows={4}
                        className="form-control"
                        placeholder="Message :"
                        onChange={handleChange}
                        required
                      ></textarea>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <div className="col-12 text-end">
                    <button type="submit" className="btn btn-primary">
                      {t("section_contact_us.contact.button")}
                    </button>
                  </div>
                </Row>
              </form>
            </div>
          </Col>

          <Col lg={4} md={6} className="col-12 order-md-1 order-2 mt-4 pt-2">
            <div className="me-lg-4">
              <div className="d-flex">
                <div className="icons text-center mx-auto">
                  <i className="uil uil-phone d-block rounded h4 mb-0"></i>
                </div>
                <div className="flex-1 ms-3">
                  <h5 className="mb-2">{t("section_contact_us.contact.phone")}</h5>
                  <a href="https://wa.me/6281230577970" className="text-muted">
                    +6281230577970
                  </a>
                </div>
              </div>

              <div className="d-flex mt-4">
                <div className="icons text-center mx-auto">
                  <i className="uil uil-map-marker d-block rounded h4 mb-0"></i>
                </div>
                <div className="flex-1 ms-3">
                  <h5 className="mb-2">{t("section_contact_us.contact.location")}</h5>
                  <p className="text-muted mb-2">
                    The City Tower, Level 12-1n Jl. MH. Thamrin No 81, Central
                    Jakarta, Indonesia
                  </p>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
